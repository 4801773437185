<template>
  <!--Logo start-->
  <div class="logo-main">
    <div class="logo-normal">
      <img src="../../../assets/images/hh-logo.png" height="22" />
    </div>
    <div class="logo-mini">
      <img src="../../../assets/images/hh-logo.png" height="22" />
    </div>
  </div>
  <!--Logo End-->
</template>

<script>
export default {
  props: {
    color: { type: Boolean, default: false }
  }
}
</script>
