<template>
  <router-view />
</template>

<script>
// import { onMounted, onUnmounted, computed } from 'vue'
// import { useStore } from 'vuex'
import '@/plugins/styles'

export default {
  name: 'App',
  mounted() {
    this.$store.dispatch('setting/setSetting')

    window.addEventListener('resize', this.resizePlugin)

    setTimeout(() => {
      this.resizePlugin()
    }, 200)
  },
  unmounted() {
    window.removeEventListener('resize', this.resizePlugin)
  },
  methods: {
    resizePlugin() {
      const sidebarResponsive = document.querySelector('[data-sidebar="responsive"]')

      if (window.innerWidth < 1025) {
        if (sidebarResponsive !== null) {
          if (!sidebarResponsive.classList.contains('sidebar-mini')) {
            sidebarResponsive.classList.add('on-resize')
            this.$store.dispatch('setting/sidebar_type', [...this.sidebarType, 'sidebar-mini'])
          }
        }
      } else {
        if (sidebarResponsive !== null) {
          if (sidebarResponsive.classList.contains('sidebar-mini') && sidebarResponsive.classList.contains('on-resize')) {
            sidebarResponsive.classList.remove('on-resize')
            this.$store.dispatch(
              'setting/sidebar_type',
              this.sidebarType.filter((item) => item !== 'sidebar-mini')
            )
          }
        }
      }
    }
  },
  computed: {
    sidebarType() {
      return this.$store.getters['setting/sidebar_type']
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/custom-vue/scss/styles.scss';
</style>
