import { client } from '../Api.js'

export default {
  async authorize(loginParams) {
    return await client.post(`/users/authorize`, loginParams)
  },

  async logout() {
    return await client.post(`/users/logout`)
  },

  async register(registerParams) {
    return await client.post(`/users/register`, registerParams)
  },

  async verifyEmail(verifyEmailParams) {
    return await client.post(`/users/verify`, verifyEmailParams)
  },

  async resetPassword(resetPasswordParams) {
    return await client.post(`/users/reset-password`, resetPasswordParams)
  },

  async resetNewPassword(resetNewPasswordParams) {
    return await client.post(`/users/reset-new-password`, resetNewPasswordParams)
  }
}
