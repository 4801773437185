import { defineStore } from 'pinia'

import Api from '../api/Api.js'

export const AuthStore = defineStore('AuthStore', {
  state: () => ({
    redirectUrl: window.localStorage.redirectUrl || null,
    user: window.localStorage.user ? JSON.parse(window.localStorage.user) : null,
    token: window.localStorage.AuthToken ? window.localStorage.AuthToken : null
  }),

  getters: {
    getRedirectUrl(state) {
      return state.redirectUrl
    },
    getUser(state) {
      return state.user
    },
    isAuthenticated(state) {
      return Boolean(state.token)
    }
  },

  actions: {
    async authorize(loginParams) {
      const { data } = await Api.UserCalls.authorize(loginParams)

      this.user = data.user
      this.token = data.token
      window.localStorage.AuthToken = data.token

      return data
    },
    async logout() {
      const { data } = await Api.UserCalls.logout()
      // delete window.localStorage.AuthToken
      window.localStorage.clear()
      this.user = null
      this.token = null
      return data
    },
    setRedirectUrl(url) {
      if (!url) {
        this.redirectUrl = null
        delete window.localStorage.redirectUrl
        return
      }

      this.redirectUrl = url
      window.localStorage.redirectUrl = url
    },
    setAuthorizedUser(user) {
      if (!user) {
        this.user = null
        delete window.localStorage.user
        return
      }

      this.user = user
      window.localStorage.user = JSON.stringify(user)
    },
    async register(registerParams) {
      const { data } = await Api.UserCalls.register(registerParams)
      return data
    },

    async verifyEmail(verifyEmailParams) {
      const { data } = await Api.UserCalls.verifyEmail(verifyEmailParams)
      return data
    },

    async resetPassword(resetPasswordParams) {
      const { data } = await Api.UserCalls.resetPassword(resetPasswordParams)
      return data
    },

    async resetNewPassword(resetNewPasswordParams) {
      const { data } = await Api.UserCalls.resetNewPassword(resetNewPasswordParams)
      return data
    }
  }
})

export default AuthStore
