import axios from 'axios'

import UserCalls from './modules/UserCalls.js'

export const client = axios.create({
  baseURL: /*import.meta.env.VITE_BACKGEND_URL + */ '/api/',
  timeout: 30000,
  // headers: {
  //   'api-key': apiKey,
  //   'Content-Type': 'application/json; charset=utf-8',
  // },
  // Set up a transformRequest function
  transformRequest: [
    function (data, headers) {
      // Modify headers before the request is sent
      headers['Content-Type'] = 'application/json; charset=utf-8'

      const token = window.localStorage.AuthToken

      if (token) {
        // headers['api-key'] = token
        headers['Authorization'] = 'Bearer ' + token
      }
      // return data
      return JSON.stringify(data)
    }
  ],
  transformResponse: [
    function (data) {
      if (typeof data === 'string') {
        data = JSON.parse(data)
      }

      // console.log("BLAK", data);
      // console.log("Data is ", typeof data)

      // if (data && data.data && data.data.data) {
      //   return data.data.data
      // }

      if (data.data) {
        return data.data
      }

      return data
    }
  ]
})

export const Api = {
  UserCalls
}

export default Api
